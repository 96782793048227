/**
 * Theme
 *
 * Adjustable theme settings.
 *
 *============================================================================*/



/* Theme
 *============================================================================*/

$colors-themes: (
  primary: (
    theme    : #E04644,
    primary  : rgba(255,255,255,1),
    secondary: rgba(255,255,255,.8),
    tertiary : rgba(255,255,255,.15),
  ),
  accent: (
    theme    : #4DA456,
    primary  : rgba(255,255,255,1),
    secondary: rgba(255,255,255,.8),
    tertiary : rgba(255,255,255,.15),
  ),
  warn: (
    theme    : #dc143c,
    primary  : rgba(255,255,255,1),
    secondary: rgba(255,255,255,.8),
    tertiary : rgba(255,255,255,.15),
  ),
  backdrop: (
    theme    : #f6f6f6,
    primary  : rgba(0,0,0,.9),
    secondary: rgba(0,0,0,.7),
    tertiary : rgba(0,0,0,.15),
  ),
  panel: (
    theme    : #fff,
    primary  : rgba(0,0,0,.9),
    secondary: rgba(0,0,0,.7),
    tertiary : rgba(0,0,0,.15),
  ),
  sidebar: (
    width    : 256px,   /* Sidebar width (adjusting this number also changes padding-left on the main element)  */
    width-mob: 48px,    /* Sidebar width on mobile */
    scaling  : 16px,    /* Sidebar elements scale based on this number */
    theme    : #ffffff, /* Sidebar background color */ /* #262626 - dark sidebar */
    contrast : #262626, /* Sidebar text color */ /* #ffffff - dark sidebar */
    accent   : #E04644, /* Sidebar icon color */ /* #80B2ED - dark sidebar */
    active   : #ffe7e7, /* Background color of active items in sidebar */ /* #283B54 - dark sidebar */
    hover    : #dedede, /* Background color of hovered items in sidebar */ /* #363636 - dark sidebar */
    position : 1,       /* Sidebar position, 0 = left, 1 = right */
    dd-b     : #d0d0d0, /* Dropdown border color */ /* rgba(255,255,255,.3) - dark sidebar */
    dd-b-a   : #737373, /* Dropdown border color - active */ /* white - dark sidebar */
    border   : rgba(0,0,0,.15)
  )
);



/* Generating CSS variables in :root
 *============================================================================*/

:root {
  @each $group-name, $group in $colors-themes {
    @each $color-name, $color-value in $group {
      --#{$group-name}-#{$color-name}: #{$color-value};
    }
  }
}



/* Generating theme classes
 *============================================================================*/

@each $group-name, $group in $colors-themes {
  .#{$group-name} {
    --theme    : var(--#{$group-name}-theme);
    --primary  : var(--#{$group-name}-primary);
    --secondary: var(--#{$group-name}-secondary);
    --tertiary : var(--#{$group-name}-tertiary );
  }
}
